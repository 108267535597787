  
  .label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #394a56;
    margin: 10px;
  }
  
  .label-text {
    margin-left: 16px;
  }
  
  .toggle {
    isolation: isolate;
    position: relative;
    height: 30px;
    width: 60px;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid white;
    box-shadow: -1px 2px 2px 0px #ffffff, 2px 3px 9px 0px #d1d9e6, 1px 2px 4px 0px #d1d9e6 inset, -2px 0px 4px 0px #ffffff inset;
    
  }
  
  .toggle-state {
    display: none;
  }
  
  .indicator {
    height: 100%;
    width: 200%;
    background: #ecf0f3;
    border-radius: 15px;
    display: flex;
    align-items: center;
    font-size: 10px;
    /* transform: translate3d(-75%, 0, 0); */
    transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
    /* box-shadow:
      -8px -4px 8px 0px #ffffff,
      8px 4px 12px 0px #d1d9e6; */
  }
  
  .toggle-state:checked ~ .indicator {
    transform: translate3d(25%, 0, 0);
  }