.navbar {
  box-shadow: rgb(204 204 204) 6px 2px 14px 2px;
  display: flex;
  align-items: center;
  min-height: 62px;
  background-color: #EDEEF2;
}

.nav {
  display: flex;
  /* flex-grow: 1; */
  align-items: center;
  cursor: pointer;
  width: fit-content;
  /* position: absolute; */
}

.heading {
  flex-grow: 1;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  color: #000000;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 2;
}

.modal-content {
  position: relative;
  background-color: white;
  padding: 10px;
  width: 310px;
  border-radius: 30px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

#yt {
  /* Your styles here */
  width: 100% !important;
  /* margin: 15px 0 !important; */
}

.content-container {
  margin: 10px 0 !important;
}

.quiz-card {
  background: #EBEBEB;
  padding: 20px;
  border-radius: 15px;
}

.quiz-option {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}

.active-quiz-option {
  background: #cb7c46;
  font-weight: bolder;
  color: white;
}

.inactive-quiz-option {
  background: #ebc3a8;
  font-weight: 500;
}

/* Basic styling for the cross button */
.cross-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Styling for horizontal line (the top line of the cross) */
.horizontal-line {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #957259;
  transform: rotate(45deg);
}

/* Styling for vertical line (the right line of the cross) */
.vertical-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 45%;
  width: 2px;
  background-color: #957259;
  transform: rotate(45deg);
}

/* Optional: Hover effect to make the lines change color on hover */
.cross-button:hover .horizontal-line,
.cross-button:hover .vertical-line {
  background-color: red;
  /* Change to your preferred color on hover */
}

.evidence-btn {
  position: absolute;
  z-index: 1;
  left: 2%;
  bottom: 8%;
  padding: 8px;
  background: white;
  box-shadow: 5px 6px 0px 0px #ccc;
  cursor: pointer;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.gradient-border {
  --border-width: 3px;

  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 300px;
  height: 200px; */
  font-family: Lato, sans-serif;
  font-size: 25px;
  text-transform: uppercase;
  color: white;
  background: #222;
  border-radius: var(--border-width);

  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(60deg,
        hsl(224, 85%, 66%),
        hsl(269, 85%, 66%),
        hsl(314, 85%, 66%),
        hsl(359, 85%, 66%),
        hsl(44, 85%, 66%),
        hsl(89, 85%, 66%),
        hsl(134, 85%, 66%),
        hsl(179, 85%, 66%));
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s alternate infinite;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

.dur-inp {
  margin-top: 10px;
  border-radius: 10px;
  width: 40px;
  padding: 7px;
  font-size: 18px;
}

.submit-btn {
  width: 100%;
  height: 35px;
  margin-top: 10px;
  border-radius: 20px;
  background: #50C878;
  color: white;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.evidence {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 1px 1px #000000;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 2px;
  margin-left: 2px;
  width: 92%;
}

.activity {
  display: flex;
  flex-direction: column;
  position: fixed;
  padding-bottom: 8px;
  bottom: 0px;
  background: linear-gradient(to bottom, #fff0 30%, #2e2e30 31%);
  /* align-items: end; */
  width: 100%;
  border-width: 2px;
  border-color: #ffff;
}

.activity-icon {
  width: 50px;
}

.node {
  flex-grow: 1;
}

.node-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 14vh;
  background: #000000;
  border: 2px solid white;
  border-radius: 20px;
  margin: 10px 4px;
}

.middle-node {
  flex-grow: 2 !important;
  /* display: flex;
    flex-direction: column; */
  /* align-items: center; */
}

.txt {
  color: white;
  font-weight: bold;
  text-align: center;
  text-shadow: -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.box {
  width: 127px;
  height: 65px;
  background-color: #2e2e30;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  border-radius: 2rem 2rem 0 0;
}

.block {
  display: flex;
  width: 100%;
}

.app-activity {
  box-shadow: 0px 0px 20px 0px #ccc;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
}

.app-activity-container {
  margin: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* style.css */