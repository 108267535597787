.container {
    position: absolute;
    top: 50%;
    left: 50%;
    /* bottom: 0; */
    /* right: 0; */
    width: 80%;
    transform: translate(-50%, -50%);
}

.vc {
    --s: 0.1em;   /* the thickness of the line */
    --c: #957259; /* the color */
    
    color: #0000;
    padding-bottom: var(--s);
    background: 
      linear-gradient(90deg,var(--c) 50%,#000 0) calc(100% - var(--_p,0%))/200% 100%,
      linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
    -webkit-background-clip: text,padding-box;
            background-clip: text,padding-box;
    transition: 0.5s;
  }
  .vc:hover {--_p: 100%}
  
  
  /* body {
    height: 100vh;
    margin: 0;
    display: grid;
    place-content: center;
  } */
  .vc {
    margin: 10px;
    font-family: system-ui, sans-serif;
    font-size: 1.5rem;
    cursor: pointer;
    /* display: flex; */
    /* flex-direction: column; */
  }

  .brands {
    margin: 10px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    box-shadow: 5px 6px 18px 3px #ccc;
    border-radius: 15px;
  }