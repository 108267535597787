.challenge-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 75px;
    margin-left: 15px;

}
@media (max-width: 768px) {
    .challenge-container {
        margin-left: 8px;  /* Override the margin for mobile view */
        margin-right: auto; /* Center the container horizontally */
    }
}
.challenge-card {
    padding: 15px;
    box-shadow: 0px 0px 1px 1px #c1c1c1;
    min-width: 300px;
    max-width: 310px;
    border-radius: 10px;
    margin: 18px;
    cursor: pointer;
    display: inline-block; /* This ensures the challenge cards are displayed inline */
    margin-right: 10px; /* Adjust the margin between cards */
}


/* Update card colors based on percentage */
.white {
    background-color: #f2f2f2;
}

.light-bronze {
    background-color: #e1aa85d2; /* Bronze */
}

.bronze {
    background-color: #c17746d2; /* Bronze */
}

.light-silver {
    background-color: #dad9d9cd; /* Silver */
}

.silver {
    background-color: #b8b7b7d1; /* Silver */
}

.light-gold {
    background-color: #eac912af; /* Light Gold */
}

.gold {
    background-color: #fbcd15cb; /* Gold */
}

.my-challenge-card {
    border: #C6C6C8;
    border-width: 3px;
    border-radius: 30px;
}

.shop-challenge-card {
    border: #C6C6C8;
    border-width: 3px;
    border-radius: 30px;
}

.shop-challenge-card > p {
    color: #0b0b0b;
    font-weight: bold;
}

.my-challenge-card > p {
    color: #0b0b0b;
    font-weight: bold;
}

.challenge-img {
    margin-top: 10px;
    width: 100%;
    border-radius: 10px;
    height: auto;
}

.searchBox {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.searchInp {
    width: 60%;
    height: 24px;
}

.searchBtn {
    background: #ccc;
    cursor: pointer;
}

.tab-head {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding: 10px 0;
    box-shadow: 0px 0px 1px 1px #C6C6C8;
    border-radius: 0px;
    margin: 0px 0px 20px 0px;
    background-color: #fcf9f9;
}

.tab-btn {
    cursor: pointer;
    padding: 5px;
}

.active-tab {
    /* background-color: antiquewhite; */
    font-weight: bold;
    border: #515151 solid 1px;
    border-radius: 20px;
    font-size: 14px;
    background-color: rgb(244, 244, 244);
}

.inactive-tab {
    background-color: #fff;
    border: #ebebeb solid 1px;
    border-radius: 20px;
    font-size: 14px;

}

.shop-btn {
    background: #ccc;
    width: fit-content;
    padding: 8px;
    border-radius: 7px;
    color: #000000;
    font-weight: bold;
}

.challenge-user-info {
    position: absolute;
    bottom: 10px; /* Adjust the positioning from the bottom */
    left: 0;
    right: 0;
    margin: 0 auto; /* Center the box horizontally */
    text-align: center; /* Center the text within the box */
    font-weight: bold;
    padding: 0 10px;
    border-radius: 12px;
    opacity: .9;

}

.challenge-details-row {
  display: flex;
  justify-content: center;
  width: 100%; /* Ensure the row takes up full width */
  margin-left: 3px;
}

.challenge-details-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%; /* Set the width to 33% */
  height: 78px; /* Set a fixed height */
  border: 1px solid #5b5b5b;
  border-radius: 10px;
  margin-right: 10px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.998);
  
}

.user-details-row {
  display: flex;
  justify-content: center;
  width: 100%; /* Ensure the row takes up full width */
}

.user-details-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 19%; /* Set the width to 33% */
  margin-bottom: 12px;
  padding: 5px;
  color: #414141df;
  font-size: 15px;
  
}

.challenge-details-box img {
  width: 40px; /* Increase the icon size */
  height: 40px; /* Increase the icon size */
}
/* challenges-style.css */

/* Filter buttons */
.filter-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-buttons button {
  margin: 3px 3px;
  padding: 8px 18px;
  border: solid;
  border-color: #dcdcdc;
  border-width: 1px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.599); /* white when deactivated */
  color: #333;
  cursor: pointer;
}

.filter-buttons button.active {
  background-color: #dedede; /* Bronze when activated */
}
.filter-buttons button img {
  width: 26px; /* Adjust the width as needed */
  height: 26px; /* Adjust the height as needed */
}
