/* src/pages/ViewMap/CompletionModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  animation: fadeIn 1s ease-in-out;
}

.modal-content {
  background: linear-gradient(135deg, #ffefba, #ffffff);
  padding: 15px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.6);
  position: relative;
  animation: slideIn 0.5s ease-out;
}

.modal-content .trophy {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.modal-content h2 {
  font-size: 2.5rem;
  color: #e67e22;
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px #fff;
}

.modal-content p {
  font-size: 1.0rem;
  color: #34495e;
  margin-bottom: 30px;
}

.modal-content button {
  background: #e74c3c;
  border: none;
  padding: 10px 10px;
  font-size: 1.1rem;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s;
}

.modal-content button:hover {
  background: #c0392b;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-50px); }
  to { transform: translateY(0); }
}

